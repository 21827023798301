<template>
	<div class="open-success-page">
		
		<van-row class="content">
			<van-col span="24">
				<img :src="icon" alt="" class="icon-success">
			</van-col>
			<van-col span="24" class="notice-title">{{translate('under_review')}}</van-col>
			<van-col span="24" class="notice-desc">{{translate('pre_trial_is_generally_completed')}}</van-col>
		</van-row>
	</div>
</template>

<script>
	export default {
		name: 'account_success',
		data() {
			return {
				resourceURL: this.$axios.defaults.resourceURL,
				
				icon: this.$axios.defaults.resourceURL + "images/icon_open_user_success.png",

			}
		},
	}
</script>